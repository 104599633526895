import { Carousel } from "react-bootstrap";

export const Partners = () => {
  return (
    <section className="partners">
      <div className="partners-container">
        <div className="partners__title">
          <p>
            We have partnered with <b>50+ clients</b> to provide our services
          </p>
        </div>
        <div className="partners__carousel">
          <Carousel>
            <Carousel.Item interval={2000}>
              <div className="partners__main-content">
                <div className="partners__image-container">
                  <img src="/assets/img/accenture.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/colive.svg"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/cybrilla.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/euro_school.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/everest_fleet.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/ibm.svg"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/indorama.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/itc_limited.png"></img>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="partners__main-content">
                <div className="partners__image-container">
                  <img src="/assets/img/jupiter.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/legato.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/lululemon.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/morgan_stanley.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/mpl.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/narayana.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/swiggy.svg"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/tekion.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/wipro.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/zolo.png"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/zomato.png"></img>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </section>
  );
};
