import { CustomNavbar } from "../../components/Navbar/CustomNavbar";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import { Footer } from "../../components/Footer/Footer";
import { Services } from "../../components/Services/Services";

export const ServicesScreen = () => {
  return (
    <div className="products-screen">
      <CustomNavbar />
      <Services />
      <ContactUs />
      <Footer />
    </div>
  );
};
