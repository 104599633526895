import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faLayerGroup,
  faRecycle,
  faSnowflake,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

export const PackagingProducts = () => {
  return (
    <div className="packaging-products">
      <div className="packaging-products__products-container">
        {/* <div className="packaging-products__header">
            <p></p>
        </div>  A title for the products */}
      </div>

      <ProductsGalleryAndText />
      <ServicesSection />
      <EventsSection />
      <Advantages />
      <Hygiene />
      {/* <WhyPlastic /> */}
    </div>
  );
};

const Hygiene = () => {
  return (
    <section className="hygiene">
      <div className="hygiene__text">
        <h2>Hygiene</h2>
        <br />
        <p>
          Hygienic handling of food and beverages is particularly important in
          the catering industry. That is why we at Vytal, together with our
          suppliers, have invested a lot of work in designing our containers so
          that they are easy to wash. This enables us to offer our partners
          containers that can be washed according to the applicable hygiene
          standards and are sustainable at the same time.
        </p>

        <div className="hygiene__tests">
          <span className="hygiene__icon">
            <FontAwesomeIcon icon={faCheckSquare} size="2x" />
          </span>
          <span className="hygiene__icon">Anti microbial Testing</span>
        </div>
        <div className="hygiene__tests">
          <span className="hygiene__icon">
            <FontAwesomeIcon icon={faCheckSquare} size="2x" />
          </span>
          <span className="hygiene__icon">Hard metal testing</span>
        </div>
        <div className="hygiene__tests">
          <span className="hygiene__icon">
            <FontAwesomeIcon icon={faCheckSquare} size="2x" />
          </span>
          <span className="hygiene__icon">Migration Testing</span>
        </div>
        <div className="hygiene__tests">
          <span className="hygiene__icon">
            <FontAwesomeIcon icon={faCheckSquare} size="2x" />
          </span>
          <span className="hygiene__icon">Hard Water Testing</span>
        </div>
      </div>
      <div className="hygiene__img">
        {/* <img src="assets/img/hygiene.png"></img> */}
        <img src="assets/img/dishwasher.png"></img>
      </div>
    </section>
  );
};

const WhyPlastic = () => {
  return (
    <div className="products__why-plastic">
      <div className="products__100vs1-img">
        <img
          alt="100vs1"
          src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/100vs1.jpg"
        />
      </div>
      <div className="products__why-plastic-text">
        <div className="products__pretitle">Why (Re)use plastic?</div>
        <div className="products__title">Our take on it</div>

        <div className="products__details">
          75% of food served in India is hot curries. We need a material that
          can not only cater to the market but is also environment friendly.
          Paper, Bagasse, and other materials usually do are not able to meet
          these qualities and, in most cases, have a plastic lining to handle
          food which makes them non-biodegradable. We strive to address this
          problem while improving customer experience. <br />
          <br />
          Plastic is one of the best invention of human kind and we use as it is
          strong, durable, lightweight and versatile. There is a lot of valid
          concern regarding how plastic impacts the environment and we take this
          very seriously. Single-use plastic items pollute ecosystems if not
          disposed of responsibly, but if we can use plastics repeatedly, there
          is nothing more environmentally impactful than that. <br />
          <br />
          Polypropylene (PP) is durable, hygienic, and shatter-resistant
          plastics. It absorbs very little water and has good chemical
          resistance. PP can be reused and recycled, which reduces waste and
          gives the product several lives through use and reuse. We want to give
          our customers the products which truly enable circular economy. <br />
          <br />
          We are here to reduce single-use plastic pollution, one container at a
          time. Together, we can achieve our ambitious target of reducing 10
          million KGs of single-use plastic by 2025. Join us! <br />
          <br />
        </div>
      </div>
    </div>
  );
};

const ServicesSection = () => {
  return (
    <div className="products__gallery-and-text">
      <div className="products__text">
        <h2>End to end packaged service </h2>
        <p>
          Full day's stock of clean and sanitized service ware in the morning,
          collect it, and replace it with stock for the next day
        </p>
        <a href="#contact"> {"Contact our sales team now -> "}</a>
      </div>
      <div className="products__service-illustration">
        <img src="/assets/img/service_illustration1.png"></img>
      </div>
    </div>
  );
};

const EventsSection = () => {
  return (
    <div className="products__gallery-and-text">
      <div className="products__text">
        <h2>Organize events smoothly</h2>
        <p>
          We have serviced events with all SKUs from Chaffin's dishes to
          melamine/bonechina plates and even frills
        </p>
        <a href="#contact"> {"Contact our sales team now -> "}</a>
      </div>
      <div className="products__service-illustration">
        <img src="/assets/img/events_illustration.png"></img>
      </div>
    </div>
  );
};

const ProductsGalleryAndText = () => {
  return (
    <div className="products__gallery-and-text">
      <div className="products__text">
        <h2>Our Products</h2>
        <p>
          We can service all SKUs from Chaffin Dishes to plates to spoons. We
          procure and invest in inventory basis client's requirement
        </p>
        <Link to="products"> {"Know More ->"}</Link>
      </div>
      <div className="products__gallery">
        <div className="products__gallery-img">
          <img src="/assets/img/5-CP-black.png"></img>
          {/* <img
            src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/plate.png"
            alt="plate"
          /> */}
        </div>
        <div className="products__gallery-img">
          <img src="/assets/img/3-CP-Black.png"></img>
          {/* <img
            src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/containers.png"
            alt="plate"
          /> 
        </div>
        <div className="products__gallery-img">
          <img src="/assets/img/box.jpg"></img>
          {/* <img
            src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/stackedPlates.png"
            alt="plate"
          /> */}
        </div>
        <div className="products__gallery-img">
          <img src="/assets/img/6-CP.png"></img>
          {/* <img
            src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/box1.png"
            alt="plate"
          /> */}
        </div>
        <div className="products__gallery-img">
          <img src="/assets/img/8-CP.png"></img>
          {/* <img
            src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/box1.png"
            alt="plate"
          /> */}
        </div>
        <div className="products__gallery-img">
          <img src="/assets/img/bowl.png"></img>
          {/* <img
            src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/box1.png"
            alt="plate"
          /> */}
        </div>
      </div>
    </div>
  );
};

const Advantages = () => {
  return (
    <div className="products">
      {/* <h2 className="heading-secondary-black">Discover the Infinitybox</h2> */}
      <div className="products__container">
        {/* <div className="products__img-container">
          <div className="products__img">
            <img
              src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/plate.png"
              alt="plate"
            />
          </div>
          <div className="products__img">
            <img
              // src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/box.png"
              src="assets/img/box.png"
              alt="gif"
            />
          </div>
        </div> */}
        <div className="products__list">
          <div className="products__list-item">
            <div className="products__icon">
              <img
                src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/noun-microwave.svg"
                alt="microwave"
              />
            </div>
            <div className="products__description">
              <div className="products__head">Microwave Safe</div>
            </div>
          </div>
          <div className="products__list-item">
            <div className="products__icon">
              <img
                src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/noun-dishwasher.svg"
                alt="microwave"
              />
            </div>
            <div className="products__description">
              <div className="products__head">Dishwasher Safe</div>
            </div>
          </div>
          <div className="products__list-item">
            <div className="products__icon">
              <FontAwesomeIcon icon={faSnowflake} />
            </div>
            <div className="products__description">
              <div className="products__head">Freezer Safe</div>
            </div>
          </div>
          <div className="products__list-item">
            <div className="products__icon">
              <FontAwesomeIcon icon={faRecycle} />
            </div>
            <div className="products__description">
              <div className="products__head">Reusable</div>
            </div>
          </div>
          <div className="products__list-item">
            <div className="products__icon">
              <FontAwesomeIcon icon={faLayerGroup} />
            </div>
            <div className="products__description">
              <div className="products__head">Ergonomic design</div>
            </div>
          </div>
          <div className="products__list-item">
            <div className="products__icon">
              <img src="/assets/img/bpaFree.svg" alt="microwave" />
            </div>
            <div className="products__description">
              <div className="products__head">BPA Free</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
