export const ContactUs = () => {
  return (
    <section className="contact-us" id="contact">
      <div className="contact-us__container">
        <div className="contact-us__title">
          <h3>Take Charge of your Corporate Sustainability Goals </h3>
          <p>Contact our sales team now</p>
        </div>
        <form class="contact-form" role="form">
          <div class="form-group">
            <div class="col-sm-12">
              <input
                type="text"
                class="form-control"
                id="name"
                placeholder="NAME"
                name="name"
                required
              />
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-12">
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="EMAIL"
                name="email"
                required
              />
            </div>
          </div>

          <textarea
            class="form-control"
            rows="5"
            placeholder="MESSAGE"
            name="message"
            required
          ></textarea>

          <button
            class="btn btn-primary send-button"
            id="submit"
            type="submit"
            value="SEND"
          >
            <div class="alt-send-button">
              <i class="fa fa-paper-plane"></i>
              <span class="send-text">SEND</span>
            </div>
          </button>
        </form>
      </div>
    </section>
  );
};
