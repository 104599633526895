export const WhyUs = () => {
  return (
    <section className="why-us">
      <div className="why-us__title">
        <p>WHY INFINITYBOX</p>
        <h2>Your business. Your customers.</h2>
        <h2>Our Expertise.</h2>
        <br />
        <p>
          InfinityBox offers your business the tools, software and expertise to
          manage your business in a easy and effecient way
        </p>
      </div>
      <br />
      <br />
      <br />
      <div className="why-us__content">
        <div className="why-us__card">
          <h6>Expertise in Reusable Packaging</h6>
          <br />
          <p>
            We specialize in developing technology solutions for businesses like
            yours, giving us in-depth knowledge and understanding of your
            industry's unique challenges and requirements.
          </p>
        </div>

        <div className="why-us__card">
          <h6>User-Friendly Interface</h6>
          <br />
          <p>
            Our SaaS products are designed with a focus on usability, ensuring
            that even non-technical users can navigate and utilize our software
            with ease.
          </p>
        </div>
        <div className="why-us__card">
          <h6>Scalable and Flexible Solutions</h6>
          <br />
          <p>
            As your business grows, our SaaS products can scale with you. We
            offer flexible pricing plans and customization options to meet your
            evolving needs .
          </p>
        </div>
      </div>
    </section>
  );
};
