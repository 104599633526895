import {
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom/cjs/react-router-dom";
import "./App.scss";
import ScrollToTop from "./ScrollToTop";
import { AboutUsScreen } from "./pages/About/AboutUsScreen";
import Blog from "./pages/Blog/BlogScreen";
import { CaseStudyScreen } from "./pages/CaseStudy/CaseStudyScreen";
import Faq from "./pages/Faq/Faq";
import { FoodPackagingServiceScreen } from "./pages/FoodPackaging/FoodPackagingServiceScreen";
import { HomeScreen } from "./pages/Home/HomeScreen";
import OurProductDetailsScreen from "./pages/OurProductDetails/OurProductDetailsScreen";
import { OurProductsScreen } from "./pages/Products/OurProductsScreen";
import { ServicesScreen } from "./pages/Products/ServicesScreen";
import { SoftwareServicesScreen } from "./pages/SoftwareServices/SoftwareServices";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route path={"/"} exact component={HomeScreen}></Route>
          <Route path={"/about"} exact component={AboutUsScreen}></Route>
          <Route path={"/services"} exact component={ServicesScreen}></Route>
          <Route
            path={"/software-services"}
            exact
            component={SoftwareServicesScreen}
          ></Route>
          <Route
            path={"/food-packaging-services"}
            exact
            component={FoodPackagingServiceScreen}
          ></Route>
          <Route path={"/blogs"} exact component={Blog}></Route>
          <Route path={"/faq"} exact component={Faq}></Route>
          <Route path={"/products"} exact component={OurProductsScreen}></Route>
  <Route path={"/products-details"} exact component={OurProductDetailsScreen}></Route> 

  {/*<Route path={"/products-details"} exact component={OurProductDetailsScreen}></Route> */}
          <Route path={"/case-study"} exact component={CaseStudyScreen}></Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
