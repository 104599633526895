import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel } from "react-bootstrap";

export const Testimonials = () => {
  return (
    <section className="testimonials">
      <div className="testimonials__title">
        <h3>Testimonials</h3>
      </div>
      <Carousel>
        <Carousel.Item>
          <div className="testimonials__main-content">
            <div className="testimonials__testimonial">
              <p>
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faQuoteLeft}></FontAwesomeIcon>
                </span>
                {"  "}InfinityBox is an interesting concept and need of the hour
                as it tackles plastic pollution. I am looking forward to smart
                bins being installed for returning containers.
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
                </span>
              </p>

              <div className="testimonials__name">Aniket Deb</div>
              <div className="testimonials__designation">Designation</div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="testimonials__main-content">
            <div className="testimonials__testimonial">
              <p>
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faQuoteLeft}></FontAwesomeIcon>
                </span>
                {"  "}InfinityBox is a solution which we must incorporate in our
                lives right away. Having implemented the Boto Project, we've
                seen how massive the plastic menace is and it is high time we
                change our practices
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
                </span>
              </p>

              <div className="testimonials__name">Amishi Shah</div>
              <div className="testimonials__designation">Designation</div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="testimonials__main-content">
            <div className="testimonials__testimonial">
              <p>
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faQuoteLeft}></FontAwesomeIcon>
                </span>
                {"  "}With its circular economy model, InfinityBox has the
                potential to massively reduce the amount of single-use plastic
                used in food delivery containers. I am very excited to see it
                scale.
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
                </span>
              </p>

              <div className="testimonials__name">Madhav Dhatt</div>
              <div className="testimonials__designation">Designation</div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="testimonials__main-content">
            <div className="testimonials__testimonial">
              <p>
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faQuoteLeft}></FontAwesomeIcon>
                </span>
                {"  "}InfinityBox is a solution that everyone’s thought about at
                least once but no one actually went ahead and implemented. Kudos
                to these guys for providing us all a platform to contribute to
                the environment.
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
                </span>
              </p>

              <div className="testimonials__name">Shivank Sharma</div>
              <div className="testimonials__designation">Designation</div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="testimonials__main-content">
            <div className="testimonials__testimonial">
              <p>
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faQuoteLeft}></FontAwesomeIcon>
                </span>
                {"  "}I feel great to have witnessed concept to execution of a
                circular economy model in food packaging by InfinityBox's
                innovative model. The concept of smart bins, especially, is I
                believe revolutionary.
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
                </span>
              </p>

              <div className="testimonials__name">Sumant Parimal</div>
              <div className="testimonials__designation">Designation</div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </section>
  );
};
