import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

export const TeamCard = ({ name, img, designation, link }) => {
  return (
    <div className="team-card">
      <div className="team-card__img">
        <img src={img}></img>
      </div>
      <br />
      <div className="team-card__name">{name}</div>
      <div className="team-card__designation">{designation}</div>

      <div className="team-card__link">
        <a href={link}>
          <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
        </a>
      </div>
    </div>
  );
};
