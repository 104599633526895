import { useEffect, useRef, useState } from "react";

export const Vision = () => {
  const plasticKg = 200000;
  const orderServed = 3000000;
  const energyReduced = 40;
  const waterConsumption = 50;
  const interval = 5000;

  const [plasticKgState, setPlasticKg] = useState(0);
  const [orderServedState, setOrderServed] = useState(0);
  const [energyReducedState, setEnergyReduced] = useState(0);
  const [waterConsumptionState, setWaterConsumption] = useState(0);
  const plasticKgStateRef = useRef(plasticKgState);
  const orderServedStateRef = useRef(orderServedState);
  const energyReducedStateRef = useRef(energyReducedState);
  const waterConsumptionStateRef = useRef(waterConsumptionState);
  plasticKgStateRef.current = plasticKgState;
  orderServedStateRef.current = orderServedState;
  energyReducedStateRef.current = energyReducedState;
  waterConsumptionStateRef.current = waterConsumptionState;
  useEffect(() => {
    setNumbers();
  }, []);

  const setNumbers = () => {
    var counter1 = setInterval(() => {
      setPlasticKg((plasticKg) => plasticKg + 500);
      if (plasticKgStateRef.current >= plasticKg) {
        clearInterval(counter1);
        setPlasticKg(plasticKg);
      }
    }, 10);
    var counter2 = setInterval(() => {
      setOrderServed((orderServed) => orderServed + 5000);
      if (orderServedStateRef.current >= orderServed) {
        clearInterval(counter2);
        setOrderServed(orderServed);
      }
    }, 5);
    var counter3 = setInterval(() => {
      setEnergyReduced((energyReduced) => energyReduced + 1);
      if (energyReducedStateRef.current >= energyReduced) {
        clearInterval(counter3);
        setEnergyReduced(energyReduced);
      }
    }, 100);
    var counter4 = setInterval(() => {
      setWaterConsumption((waterConsumption) => waterConsumption + 1);
      if (waterConsumptionStateRef.current >= waterConsumption) {
        clearInterval(counter4);
        setWaterConsumption(waterConsumption);
      }
    }, 100);
  };

  return (
    <div className="vision">
      <div className="vision__main-content">
        <div className="vision__column">
          <div className="vision__title">{plasticKgState}+</div>
          <div className="vision__text">KGs of single use plastic reduces</div>
        </div>
        <div className="vision__column">
          <div className="vision__title">{energyReducedState}%</div>
          <div className="vision__text">
            energy reduced while cleaning cutlery
          </div>
        </div>
        <div className="vision__column">
          <div className="vision__title">{waterConsumptionState}%</div>
          <div className="vision__text">
            water consumption reduced used for cleaning
          </div>
        </div>
        <div className="vision__column">
          <div className="vision__title">{orderServedState}+</div>
          <div className="vision__text">orders served and counting</div>
        </div>
      </div>
    </div>
    // <Container
    //   fluid
    //   className="text-white vision-container d-flex align-items-center justify-content-around"
    // >
    //   <Container className="vision-container__child">
    //     <Container className="vision-container__img">
    //       <img src="/assets/img/hygienic_cutlery.png"></img>
    //     </Container>
    //     <Container className="vision-container__heading">
    //       <h2>Single-use plastics reduction</h2>
    //     </Container>
    //     <Container className="vision-container__paragraph ">
    //       <p>
    //         InfinityBox plates are certified for 200+ uses without any
    //         leaching,hard metals,or bacteria using proprietary hygiene
    //         management.
    //       </p>
    //     </Container>
    //   </Container>

    //   <Container className="vision-container__child">
    //     <Container className="vision-container__img">
    //       <img src="/assets/img/hygienic_cutlery.png"></img>
    //     </Container>
    //     <Container className="vision-container__heading">
    //       <h2>Single-use plastics reduction</h2>
    //     </Container>
    //     <Container className="vision-container__paragraph ">
    //       <p>
    //         InfinityBox plates are certified for 200+ uses without any
    //         leaching,hard metals,or bacteria using proprietary hygiene
    //         management.
    //       </p>
    //     </Container>
    //   </Container>

    //   <Container className="vision-container__child">
    //     <Container className="vision-container__img">
    //       <img src="/assets/img/hygienic_cutlery.png"></img>
    //     </Container>
    //     <Container className="vision-container__heading">
    //       <h2>Single-use plastics reduction</h2>
    //     </Container>
    //     <Container className="vision-container__paragraph ">
    //       <p>
    //         InfinityBox plates are certified for 200+ uses without any
    //         leaching,hard metals,or bacteria using proprietary hygiene
    //         management.
    //       </p>
    //     </Container>
    //   </Container>
    // </Container>
  );
};
