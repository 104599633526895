import { CustomNavbar } from "../../components/Navbar/CustomNavbar";
import { Footer } from "../../components/Footer/Footer";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CaseStudyScreen = () => {
  return (
    <div className="case-study-screen">
      <CustomNavbar />
      <div className="case-study-screen__header">
        <div className="case-study-screen__title">
          <h1 className="case-study-screen__title">A client in bengaluru</h1>
          <p>
            How this company used the infnity box services to reduce their cost
            and improve effeciency.
          </p>
        </div>
      </div>
      <div className="case-study">
        <div className="case-study__title">
          <h3>OVERVIEW</h3>
        </div>
        <div className="case-study__container">
          <div className="case-study__content">
            Content and engagement have been presented to B28 brands as another
            scenario where the phrase. *if vou build it they will come was
            thought to apply. Basically, if you created something great, people
            will just discover it due to its greatness. But we know that's not
            true today, nor was it ever.
            <br />
            <br />
            To achieve our goal of reaching a highly-specific target audience,
            we decided to combine Network's Sponsored Updates and Audience
            Discovery Targeting with our own proprietary social attribution
            tools.
          </div>

          <div className="case-study__testimonial">
            <FontAwesomeIcon icon={faQuoteLeft}></FontAwesomeIcon>
            {"  "}
            We face utility manpower issues daily, and this leads to significant
            issues with following processes, hygiene management, and client
            servicing. Moreover, it is quite costly!{" "}
            <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
            <br />
            <br />
            <div className="testimonials__name">Senior Manager</div>
            <div className="testimonials__designation">Vendor</div>
          </div>
        </div>
        <br />
        <div className="case-study__title">
          <h3>CHALLENGES</h3>
        </div>
        <div className="case-study__container">
          <div className="case-study__content">
            Content and engagement have been presented to B28 brands as another
            scenario where the phrase. *if vou build it they will come was
            thought to apply. Basically, if you created something great, people
            will just discover it due to its greatness. But we know that's not
            true today, nor was it ever.
          </div>

          <div className="case-study__challenges">
            <ul>
              <li>Have to invest in plates and dishwasher</li>
              <li>Huge operational challenges</li>
              <li>14 utility staff + high chemical, water, and energy cost</li>
              <li>Hygiene concerns being raised by employees</li>
            </ul>
          </div>
        </div>

        <br />
        <br />
        <div className="case-study__title">
          <h3>SOLUTION</h3>
        </div>
        <div className="case-study__container">
          <div className="case-study__content">
            Content and engagement have been presented to B28 brands as another
            scenario where the phrase. *if vou build it they will come was
            thought to apply. Basically, if you created something great, people
            will just discover it due to its greatness. But we know that's not
            true today, nor was it ever.
          </div>

          <div className="case-study__challenges">
            <ul>
              <li>Have to invest in plates and dishwasher</li>
              <li>Huge operational challenges</li>
              <li>14 utility staff + high chemical, water, and energy cost</li>
              <li>Hygiene concerns being raised by employees</li>
            </ul>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="case-study__title">
          <h3>RESULTS</h3>
        </div>
        <div className="case-study__container">
          <div className="case-study__results">
            <div className="case-study__result-card">
              <div className="case-study__result-card-title">70L</div>
              <div className="case-study__description">capex reduced</div>
            </div>
            <div className="case-study__result-card">
              <div className="case-study__result-card-title">70L</div>
              <div className="case-study__description">capex reduced</div>
            </div>
            <div className="case-study__result-card">
              <div className="case-study__result-card-title">70L</div>
              <div className="case-study__description">capex reduced</div>
            </div>
            <div className="case-study__result-card">
              <div className="case-study__result-card-title">70L</div>
              <div className="case-study__description">capex reduced</div>
            </div>
          </div>
          <div className="case-study__challenges">
            <ul>
              <li>Have to invest in plates and dishwasher</li>
              <li>Huge operational challenges</li>
              <li>14 utility staff + high chemical, water, and energy cost</li>
              <li>Hygiene concerns being raised by employees</li>
            </ul>
          </div>
        </div>
      </div>

      <ContactUs />
      <Footer />
    </div>
  );
};
