import { SoftwareServices } from "../../components/Products/SoftwareService";
import { CustomNavbar } from "../../components/Navbar/CustomNavbar";
import { WhyUs } from "../../components/WhyUs/WhyUs";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import { Footer } from "../../components/Footer/Footer";

export const SoftwareServicesScreen = () => {
  return (
    <div className="products-screen">
      <CustomNavbar />
      <SoftwareServices />
      <WhyUs />
      <ContactUs />
      <Footer />
    </div>
  );
};
