import { Carousel } from "react-bootstrap";
import ReactPlayer from "react-player";

export const Media = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  };
  return (
    <div className="media">
      <div className="media__heading">InfinityBox In The Media</div>

      <div className="media-container-1">
        <Carousel>
          <Carousel.Item interval={2000}>
            <div className="media-container-1">
              <div className="media__youtube-thumbnail">
                <ReactPlayer
                  width="350px"
                  height="210px"
                  className="video-component__react-player"
                  url={"https://www.youtube.com/watch?v=ZZBS7TuK-9M"}
                  // style={playerStyle}
                  controls
                />
                <div className="media__youtube-thumbnail-link">
                  <a href="#">
                  Adopting Circular Economy
                  </a>
                </div>
              </div>
              <div className="media__youtube-thumbnail">
                <ReactPlayer
                  width="350px"
                  height="210px"
                  className="video-component__react-player"
                  url={"https://youtube.com/shorts/ri0osudPsiA?feature=share"}
                  // style={playerStyle}
                  controls
                />
                <div className="media__youtube-thumbnail-link">
                  <a href="#">
                    Switch to InfinityBox Containers.
                  </a>
                </div>
              </div>
              <div className="media__youtube-thumbnail">
                <ReactPlayer
                  width="350px"
                  height="210px"
                  className="video-component__react-player"
                  url={"https://www.youtube.com/watch?v=R8pX0WBXB6w"}
                  // style={playerStyle}
                  controls
                />
                <div className="media__youtube-thumbnail-link">
                  <a href="#">
                    <b>Podcast</b>  Bending The Lines.
                  </a>
                </div>
              </div>
            </div>
          </Carousel.Item>
          {/* <Carousel.Item interval={2000}>
            <div className="media-container-1">
             
            </div>
          </Carousel.Item> */}
          <Carousel.Item interval={2000}>
            <div className="media-container-1">
              <div className="media__youtube-thumbnail">
                <ReactPlayer
                  width="350px"
                  height="210px"
                  className="video-component__react-player"
                  url={"https://www.youtube.com/watch?v=ik7mkG4BRAE"}
                  // style={playerStyle}
                  controls
                />
                <div className="media__youtube-thumbnail-link">
                  <a href="#">
                    How <b>InfintyBox</b> Helps Enterprises Achieve.
                  </a>
                </div>
              </div>
              <div className="media__youtube-thumbnail">
                <ReactPlayer
                  width="350px"
                  height="210px"
                  className="video-component__react-player"
                  url={"https://www.youtube.com/watch?v=WakuiGyr99I"}
                  // style={playerStyle}
                  controls
                />
                <div className="media__youtube-thumbnail-link">
                  <a href="#">
                    How <b>InfintyBox</b> Helps Enterprises Achieve.
                  </a>
                </div>
              </div>
              <div className="media__youtube-thumbnail">
                <ReactPlayer
                  width="350px"
                  height="210px"
                  className="video-component__react-player"
                  url={"https://www.youtube.com/watch?v=JrXw1C0m2mQ"}
                  // style={playerStyle}
                  controls
                />
                <div className="media__youtube-thumbnail-link">
                  <a href="#">
                  <b>Podcast</b>  Bending The Lines.
                  </a>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>

      <div className="media-container-2">
        <div className="media__news-thumbnail">
          <div className="media__news-thumbnail-logo">
            <img src="assets/img/moneycontrol.avif"></img>
          </div>
          <div className="media__news-thumbnail-link">
            <a href="https://www.moneycontrol.com/news/business/startup/packaging-as-a-service-this-start-up-is-looking-to-replace-single-use-plastic-food-containers-9612171.html">
              Packaging as a service: This start-up is looking to replace single
              use plastic containers
            </a>
          </div>
        </div>
        <div className="media__news-thumbnail">
          <div className="media__news-thumbnail-logo">
            <img src="assets/img/businessline.png"></img>
          </div>
          <div className="media__news-thumbnail-link">
            <a href="https://www.thehindubusinessline.com/companies/infinitybox-partners-with-swiggy-to-push-re-usable-food-containers/article37999938.ece">
              Clean innovation. InfinityBox partners with Swiggy to push
              re-usable food containers
            </a>
          </div>
        </div>
        <div className="media__news-thumbnail">
          <div className="media__news-thumbnail-logo">
            <img src="assets/img/vccircle.png"></img>
          </div>
          <div className="media__news-thumbnail-link">
            <a href="https://www.vccircle.com/wehventures-rainmatter-others-invest-in-reusable-packaging-platform-infinitybox">
              Weh Ventures, Rainmatter, Others Invest In Reusable Packaging
              Platform InfinityBox
            </a>
          </div>
        </div>
        <div className="media__news-thumbnail">
          <div className="media__news-thumbnail-logo">
            <img src="/assets/img/economic-times.png"></img>
          </div>
          <div className="media__news-thumbnail-link">
            <a href="https://economictimes.indiatimes.com/how-zomato-swiggy-and-others-are-making-deliveries-sustainable/primearticleshow/100341703.cms?from=mdr">
              Meet InfinityBox: an Indian startup on a mission to end single-use
              packaging in food delivery
            </a>
          </div>
        </div>
      </div>
      <div className="media-container-3"></div>
    </div>
  );
};
