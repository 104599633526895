export const WhoDoWeServe = () => {
  return (
    <section className="whoDoWeServe">
      <div className="whoDoWeServe__title">
        <h3>Want to get in touch</h3>
        <p>Our clients are spread across a plethora of avenues in India</p>
      </div>
      <div className="whoDoWeServe__main-content">
        <div className="whoDoWeServe__item">
          <div className="whoDoWeServe__image-container">
            <img src="/assets/img/banquets.png" />
          </div>
          <p className="whoDoWeServe__link">Banquets</p>
          <div className="whoDoWeServe__overlay">
            <a href="/">Know more</a>
          </div>
        </div>
        <div className="whoDoWeServe__item">
          <div className="whoDoWeServe__image-container">
            <img src="/assets/img/startups.png" />
          </div>
          <p>Startups</p>
          <div className="whoDoWeServe__overlay">
            <a href="/">Know more</a>
          </div>
        </div>
        <div className="whoDoWeServe__item">
          <div className="whoDoWeServe__image-container">
            <img src="/assets/img/universities.png" />
          </div>
          <p>Universities</p>
          <div className="whoDoWeServe__overlay">
            <a href="/">Know more</a>
          </div>
        </div>
        <div className="whoDoWeServe__item">
          <div className="whoDoWeServe__image-container">
            <img src="/assets/img/hospitals.png" />
          </div>
          <p>Hospitals</p>
          <div className="whoDoWeServe__overlay">
            <a href="/">Know more</a>
          </div>
        </div>
        <div className="whoDoWeServe__item">
          <div className="whoDoWeServe__image-container">
            <img src="/assets/img/events.png" />
          </div>
          <p>Events</p>
          <div className="whoDoWeServe__overlay">
            <a href="/">Know more</a>
          </div>
        </div>
        <div className="whoDoWeServe__item">
          <div className="whoDoWeServe__image-container">
            <img src="/assets/img/colivingspaces.png" />
          </div>
          <p>Co-Living Spaces</p>
          <div className="whoDoWeServe__overlay">
            <a href="/">Know more</a>
          </div>
        </div>
        <div className="whoDoWeServe__item">
          <div className="whoDoWeServe__image-container">
            <img src="/assets/img/cafeterias.png" />
          </div>
          <p>Cafeterias</p>
          <div className="whoDoWeServe__overlay">
            <a href="/">Know more</a>
          </div>
        </div>
        <div className="whoDoWeServe__item">
          <div className="whoDoWeServe__image-container">
            <img src="/assets/img/corporates.png" />
          </div>
          <p>Corporates</p>
          <div className="whoDoWeServe__overlay">
            <a href="/">Know more</a>
          </div>
        </div>
      </div>
    </section>
  );
};
