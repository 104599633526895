import { AboutUsHeader } from "../../components/AboutUsHeader/AboutUsHeader";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import { Footer } from "../../components/Footer/Footer";
import { Media } from "../../components/Media/Media";
import { CustomNavbar } from "../../components/Navbar/CustomNavbar";
import { TeamCard } from "../../components/TeamCard/TeamCard";

export const AboutUsScreen = () => {
  return (
    <div>
      <CustomNavbar />
      <div className="about-us">
        <AboutUsHeader />
        <br />
        <br />
        <br />
        <div className="about-us__team-title">
          <h2>Meet the team</h2>
        </div>
        <br />
        <div className="about-us__team-heading">
          <h4>Leadership</h4>
        </div>
        <div className="about-us__team">
          <TeamCard
            name={"Shashwat Gangwal"}
            img={"assets/img/shashwat.jpeg"}
            designation={"Co-founder and CEO"}
            link={"https://www.linkedin.com/in/shashwat-gangwal-infinitybox/"}
          />
          <TeamCard
            name={"Keshav Godala"}
            img={"assets/img/keshav.jpeg"}
            designation={"Co-founder"}
            link={"https://www.linkedin.com/in/keshavgodala/"}
          />
          <TeamCard
            name={"Gaurav Patel"}
            img={"assets/img/gaurav.jpeg"}
            designation={"VP Technology"}
            link={"https://www.linkedin.com/in/gaurav-patel-462206a"}
          />
          <TeamCard
            name={"Sourav Kumar"}
            img={"assets/img/sourav.jpeg"}
            designation={"VP Operations"}
            link={"https://www.linkedin.com/in/sourav-kumar-53471023"}
          />
        </div>
        <br />
        <div className="about-us__team-heading">
          <h4>Technology</h4>
        </div>
        <div className="about-us__team">
          <TeamCard
            name={"Shreyas"}
            img={"assets/img/shreyas-ananth.jpeg"}
            designation={"Full-Stack Developer"}
            link={"https://www.linkedin.com/in/shreyas-ananth-23b0b62b/"}
          />
          <TeamCard
            name={"Advaith "}
            img={"assets/img/advaith.jpeg"}
            designation={"Frontend Developer"}
            link={"https://www.linkedin.com/in/advaith-praveen"}
          />
          <TeamCard
            name={"Abhay Chaudhary"}
            img={"assets/img/abhay.jpeg"}
            designation={"Flutter Developer"}
            link={"https://www.linkedin.com/in/abhay1821"}
          />
        </div>
        <br />
        <div className="about-us__team-heading">
          <h4>Operations</h4>
        </div>
        <div className="about-us__team">
          <TeamCard
            name={"Jerry Jacob"}
            img={"assets/img/jerry.jpeg"}
            designation={"Operations Head"}
            link={"https://www.linkedin.com/in/jerry-jacob-6154a515"}
          />
          <TeamCard
            name={"Sourav Sultania"}
            img={"assets/img/souravs.jpeg"}
            designation={"Manager-Operations Excellence"}
            link={"https://www.linkedin.com/in/sourav-sultania-41b3bbb9"}
          />
          <TeamCard
            name={"Nilesh Mokal"}
            img={"assets/img/nilesh.jpeg"}
            designation={"Entrepreneur In Residence"}
            link={"https://www.linkedin.com/in/nileshmokal"}
          />
        </div>
        <InvestersAndRecognition />
        <Media />
        <ContactUs />
        <Footer />
      </div>
    </div>
  );
};

const InvestersAndRecognition = () => {
  return (
    <section className="investers">
      <h2 className="heading-secondary-black">Investors and Grants</h2>
      <div className="investers__container">
        <div className="investers__award">
          <div className="investers__img">
            <img alt="award" src="/assets/img/weh.png" />
          </div>
          {/* <div className="investers__title">India Plastic Hackathon finalists</div> */}
        </div>
        <div className="investers__award">
          <div className="investers__img">
            <img alt="award" src="/assets/img/rainmatter.png" />
          </div>
          {/* <div className="investers__title">Eureka 2020 winner</div> */}
        </div>
        <div className="investers__award">
          <div className="investers__img">
            <img alt="award" src="/assets/img/capitala.png" />
          </div>
          {/* <div className="investers__title">INAE Youth Conclave Winner</div> */}
        </div>

        <div className="investers__award">
          <div className="investers__img img-undp">
            <img
              alt="award"
              src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/undp-logo-30.svg"
            />
          </div>
          {/* <div className="investers__title">
            Most Innovative Packaging Solution
          </div> */}
        </div>

        <div className="investers__award">
          <div className="investers__img">
            <img
              alt="award"
              src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/hultprize.png"
            />
          </div>
          {/* <div className="investers__title">Winner 2021</div> */}
        </div>

        <div className="investers__award">
          <div className="investers__img">
            <img alt="award" src="/assets/img/htparekh.png" />
          </div>
          {/* <div className="investers__title">Top 15 in the world</div> */}
        </div>

        <div className="investers__award">
          <div className="investers__img">
            <img
              alt="award"
              src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/startupindia.png"
            />
          </div>
          {/* <div className="investers__title">
            National Startup India investers - Finalists
          </div> */}
        </div>
        <div className="investers__award">
          <div className="investers__img">
            <img alt="award" src="/assets/img/marico.png" />
          </div>
          {/* <div className="investers__title">Global Finalists</div> */}
        </div>
        <div className="investers__award">
          <div className="investers__img">
            <img alt="award" src="assets/img/bestseller.png" />
          </div>
          {/* <div className="investers__title">
            Young Environment Ambassador Award
          </div> */}
        </div>
      </div>
    </section>
  );
};
