import { CustomNavbar } from "../../components/Navbar/CustomNavbar";
import { PackagingProducts } from "../../components/PackagingProducts/PackagingProducts";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import { Footer } from "../../components/Footer/Footer";

export const FoodPackagingServiceScreen = () => {
  return (
    <div className="foodpackging-service-screen">
      <CustomNavbar />
      <PackagingProducts />
      <ContactUs />
      <Footer />
    </div>
  );
};
