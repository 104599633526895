import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom/cjs/react-router-dom";


export const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="footer__main-content">
          <div className="footer__col">
            <div className="footer__title">
              <div className="footer__logo">
                <img src="/assets/img/InfinityLogo.png"></img>
              </div>
            </div>
            <div className="footer__link-group">
              <Link className="footer__link" to="/">
                Home
              </Link>
              <Link className="footer__link"  to="about">
                About Us
              </Link>
              <a className="footer__link" href="products">
                Our Products
              </a>
              <Link className="footer__link" to="blogs">
                Blogs
              </Link>
            </div>
          </div>
         
          <div className="footer__col">
            <div className="footer__title">Contact Us</div>
            <div className="footer__link-group">
              <div className="footer__link" href="#">
                +91 9214592531 / 7304551633
              </div>
              <div className="footer__link" href="#">
                admin@getinfinitybox.com
              </div>
              <div className="footer__link">
                #17/1, SVR Kalpavruksh 2nd Floor, Bellandur Gate Opp. Cotton
                Quest, Ambalipura, Near HDFC Bank Sarjapur Road,
                Bengaluru-560102
              </div>
            </div>
          </div>
          {/* <div className="footer__col">
            <div className="footer__title">Why choose InfinityBox</div>
            <div className="footer__link-group">
              <a className="footer__link" href="#">
                This is footer link
              </a>
              <a className="footer__link" href="#">
                This is footer link
              </a>
              <a className="footer__link" href="#">
                This is footer link
              </a>
              <a className="footer__link" href="#">
                This is footer link
              </a>
            </div>
          </div> */}
        </div>

        <div className="footer__misc">
          <div>© 2023 InfinityBox. All rights reserved.</div>
          <div className="footer__links">
            <div className="footer__link-icon">
              <a
                className="footer__link"
                href="https://www.facebook.com/InfinityBoxCo/"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </div>
            <div className="footer__link-icon">
              <a
                className="footer__link"
                href="https://www.facebook.com/InfinityBoxCo/"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
            <div className="footer__link-icon">
              <a
                className="footer__link"
                href="https://www.facebook.com/InfinityBoxCo/"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </div>
            <div className="footer__link-icon">
              <a
                className="footer__link"
                href="https://www.facebook.com/InfinityBoxCo/"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
