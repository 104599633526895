import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { Impact } from "../../components/Impact/Impact";
import { Media } from "../../components/Media/Media";
import { Partners } from "../../components/Partners/Partners";
import { Testimonials } from "../../components/Testimonials/Testimonials";
import { Vision } from "../../components/Vision/Vision";
import { WhoDoWeServe } from "../../components/WhoDoWeServe/WhoDoWeServe";


export const HomeScreen = () => {
  return (
    <div className="homescreen">
      <Header />
      <Vision />
      <Impact />
      <Partners />
      <WhoDoWeServe />
      <ProductsCarousel />
      {/* <Products /> */}
      {/* <WhyUs /> */}
      <Media />
      <Testimonials />
      <ContactUs />
      <Footer />
    </div>
  );
};

const ProductsCarousel = () => {
  return (
    <>
      <h2 className="packaging-products__heading">Our Products</h2>
      <Carousel>
        <Carousel.Item>
          <div className="packaging-products__carousel">
            <Link to="products">
            <div className="packaging-products__carousel-img">
              <img src="/assets/img/acrylic-1.jpeg"></img>
            </div>
            </Link>
            <Link to="products">
            <div className="packaging-products__carousel-img">
              <img src="/assets/img/bone-china-1.jpeg"></img>
            </div>
            </Link>
            <Link to="products">
            <div className="packaging-products__carousel-img">
              <img src="/assets/img/chefen-1.jpeg"></img>
            </div>
            </Link>
            <Link to="products">
            <div className="packaging-products__carousel-img">
              <img src="/assets/img/melamine-1.jpeg"></img>
            </div>
            </Link>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="packaging-products__carousel">
          <Link to="products">
            <div className="packaging-products__carousel-img">
              <img src="/assets/img/acrylic-2.jpeg"></img>
            </div>
            </Link>
            <Link to="products">
            <div className="packaging-products__carousel-img">
              <img src="/assets/img/bone-china-2.jpeg"></img>
            </div>
            </Link>
            <Link to="products">
            <div className="packaging-products__carousel-img">
              <img src="/assets/img/acrylic-3.jpeg"></img>
              </div>
              </Link>
            <div className="packaging-products__carousel-img">
            <Link to="products">

              <img src="/assets/img/melamine-2.jpeg"></img>
              </Link>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="packaging-products__carousel">
          <Link to="products">
            <div className="packaging-products__carousel-img">
              <img src="/assets/img/acrylic-4.jpeg"></img>
            </div>
            </Link>
            <Link to="products">
            <div className="packaging-products__carousel-img">
              <img src="/assets/img/chefen-2.jpeg"></img>
            </div>
            </Link>
            <Link to="products">
            <div className="packaging-products__carousel-img">
              <img src="/assets/img/box-2.jpg"></img>
            </div>
            </Link>
            <Link to="products">
            <div className="packaging-products__carousel-img">
              <img src="/assets/img/box.jpg"></img>
            </div>
            </Link>
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );
};
