import { CustomNavbar } from "../Navbar/CustomNavbar";

export const Header = () => {
  return (
    <header>
      <CustomNavbar></CustomNavbar>
      <div className="header">
        <div className="header__text">
          <div className="font-weight-bold">
            <p>India's Largest</p>
            <h1 className="header__title">Sustainable Packaging Platform</h1>
            <p>
              We enable hassle-free, hygienic, and more sustainable
              food
              operations
            </p>
          </div>
        </div>

        <div className="header__gallery">
        <div className="header__gallery-img">
          <img src="/assets/img/home-screen-five.png"></img>
         
        </div>
        <div className="header__gallery-img">
          <img src="/assets/img/home-screen-four.png"></img>
         
        </div>
        <div className="header__gallery-img">
          <img src="/assets/img/home-screen-three.png"></img>
          {/* <img
            src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/box1.png"
            alt="plate"
          /> */}
        </div>
        <div className="header__gallery-img">
          <img src="/assets/img/home-screen-two.png"></img>
          {/* <img
            src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/box1.png"
            alt="plate"
          /> */}
        </div>
        <div className="header__gallery-img">
          <img src="/assets/img/home-screen-one.png"></img>
          {/* <img
            src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/box1.png"
            alt="plate"
          /> */}
        </div>
        <div className="header__gallery-img">
          <img src="/assets/img/home-screen-six.png"></img>
          {/* <img
            src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/box1.png"
            alt="plate"
          /> */}
        </div>
      </div>
      </div>
    </header>
  );
};
