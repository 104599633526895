export const Impact = () => {
  return (
    <div className="impact">
      <div className="impact__container">
        <div className="impact__title">
          <h3>India's largest sustainable packaging platform</h3>
          <p>
            We enable hassle-free, hygienic, and more sustainable food
            operations
          </p>
        </div>
        <div className="impact__main-content">
          <div className="impact-card">
            <div className="impact-card__img">
              <img src="assets/img/esg.png"></img>
            </div>
            <div className="impact-card__text">
              <div className="impact-card__title">Achieve ESG Goals</div>
              <div className="impact-card__caption">
                Reduced energy, water, and chemical consumption
              </div>
            </div>
          </div>
          <div className="impact-card">
            <div className="impact-card__img">
              <img src="assets/img/hassle-free.png"></img>
            </div>
            <div className="impact-card__text">
              <div className="impact-card__title">Hassle free ops</div>
              <div className="impact-card__caption">
                Reduction in blue collar manpower, streamlined procurement
              </div>
            </div>
          </div>
          <div className="impact-card">
            <div className="impact-card__img">
              <img src="assets/img/cost-reduction.png"></img>
            </div>
            <div className="impact-card__text">
              <div className="impact-card__title">Cost reduction</div>
              <div className="impact-card__caption">
                ZERO capex and reduced opex by upto 25%!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
